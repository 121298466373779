.title {
    font-size: 3rem;
    color: #000000;
    font-family: "Assistant";
    direction: rtl;
    text-align: center;
    margin: 1% auto;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 2% auto;
}

.description, .descriptionB {
    font-size: 1.5rem;
    direction: rtl;
    margin: 1% auto;
    width: 50%;
    text-align: center;
}

.description {
    font-family: 'AssistantR';
}

.descriptionB {
    font-family: 'Assistant';
}

/* מתחת ל-350px */
@media (max-width: 349px) {
    .title {
        font-size: 1.8rem;
    }
    .description, .descriptionB {
        font-size: 1rem;
        width: 90%;
    }
}

/* 350px - 450px */
@media (min-width: 350px) and (max-width: 450px) {
    .title {
        font-size: 2rem;
    }
    .description, .descriptionB {
        font-size: 1.2rem;
        width: 85%;
    }
}

/* 450px - 550px */
@media (min-width: 450px) and (max-width: 550px) {
    .title {
        font-size: 2.2rem;
    }
    .description, .descriptionB {
        font-size: 1.2rem;
        width: 80%;
    }
}

/* 550px - 650px */
@media (min-width: 550px) and (max-width: 650px) {
    .title {
        font-size: 2.4rem;
    }
    .description, .descriptionB {
        font-size: 1.3rem;
        width: 75%;
    }
}

/* 650px - 750px */
@media (min-width: 650px) and (max-width: 750px) {
    .title {
        font-size: 2.6rem;
    }
    .description, .descriptionB {
        font-size: 1.4rem;
        width: 70%;
    }
}

/* 750px - 850px */
@media (min-width: 750px) and (max-width: 850px) {
    .title {
        font-size: 2.7rem;
    }
    .description, .descriptionB {
        font-size: 1.45rem;
        width: 65%;
    }
}

/* 850px - 950px */
@media (min-width: 850px) and (max-width: 950px) {
    .title {
        font-size: 2.8rem;
    }
    .description, .descriptionB {
        font-size: 1.47rem;
        width: 60%;
    }
}

/* 950px - 1050px */
@media (min-width: 950px) and (max-width: 1050px) {
    .title {
        font-size: 2.9rem;
    }
    .description, .descriptionB {
        font-size: 1.48rem;
        width: 55%;
    }
}

/* 1050px - 1150px */
@media (min-width: 1050px) and (max-width: 1150px) {
    .title {
        font-size: 2.95rem;
    }
    .description, .descriptionB {
        font-size: 1.49rem;
        width: 52%;
    }
}

/* 1150px - 1250px */
@media (min-width: 1150px) and (max-width: 1250px) {
    .title {
        font-size: 3rem;
    }
    .description, .descriptionB {
        font-size: 1.5rem;
        width: 50%;
    }
}