/* Base styles */
.title {
  font-size: 3rem;
  color: #000000;
  font-family: "Assistant";
  direction: rtl;
  text-align: center;
  margin:3% auto;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 3% auto;
}

.description {
  font-family: 'AssistantR';
  font-size: 1.5rem;
  direction: rtl;
  margin: 2% auto;
  width: 50%;
  text-align: center;
}

/* Responsive styles */
@media (max-width: 350px) {
  .title {
    font-size: 1.5rem;
  }
  .description {
    font-size: 1rem;
    width: 90%;
  }
}

@media (min-width: 350px) and (max-width: 450px) {
  .title {
    font-size: 1.8rem;
  }
  .description {
    font-size: 1.1rem;
    width: 85%;
  }
}

@media (min-width: 450px) and (max-width: 550px) {
  .title {
    font-size: 2rem;
  }
  .description {
    font-size: 1.2rem;
    width: 80%;
  }
}

@media (min-width: 550px) and (max-width: 650px) {
  .title {
    font-size: 2.2rem;
  }
  .description {
    font-size: 1.3rem;
    width: 75%;
  }
}

@media (min-width: 650px) and (max-width: 750px) {
  .title {
    font-size: 2.4rem;
  }
  .description {
    font-size: 1.4rem;
    width: 70%;
  }
}

@media (min-width: 750px) and (max-width: 850px) {
  .title {
    font-size: 2.6rem;
  }
  .description {
    font-size: 1.45rem;
    width: 65%;
  }
}

@media (min-width: 850px) and (max-width: 950px) {
  .title {
    font-size: 2.8rem;
  }
  .description {
    font-size: 1.48rem;
    width: 60%;
  }
}

@media (min-width: 950px) and (max-width: 1050px) {
  .title {
    font-size: 2.9rem;
  }
  .description {
    font-size: 1.49rem;
    width: 55%;
  }
}

@media (min-width: 1050px) and (max-width: 1150px) {
  .title {
    font-size: 2.95rem;
  }
  .description {
    font-size: 1.5rem;
    width: 52%;
  }
}

@media (min-width: 1150px) and (max-width: 1250px) {
  .title {
    font-size: 3rem;
  }
  .description {
    font-size: 1.5rem;
    width: 50%;
  }
}