.whyContainer {
  position: relative;
  background: linear-gradient(135deg, rgba(255, 235, 220, 0.8), rgba(255, 215, 200, 0.8));
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid rgba(255, 200, 180, 0.6);
  transition: all 0.3s ease;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  box-shadow: 0 8px 32px rgba(255, 180, 160, 0.3),
              inset 0 0 20px rgba(255, 255, 255, 0.7);
  padding: 1rem;
  margin: 15px auto;
  width: 320px;
}

.whyContainer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    rgba(255, 235, 220, 0.4),
    rgba(255, 215, 200, 0.4),
    rgba(255, 200, 180, 0.4),
    rgba(255, 180, 160, 0.4),
    rgba(255, 170, 150, 0.4)
  );
  z-index: -1;
  filter: blur(20px);
}

.whyContainer:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(255, 180, 160, 0.4),
              inset 0 0 25px rgba(255, 255, 255, 0.8);
  background: linear-gradient(135deg, rgba(255, 225, 210, 0.9), rgba(255, 200, 190, 0.9));
}
  .icon {
    margin:2% auto;
    width:30%;
  }
  
  .title {
    font-family: 'Assistant';
    font-size: 1.5rem;
    color: #333;
    margin:2% auto;
    text-align: center;
  }
  
  .description {
    font-family: 'AssistantR';
    font-size: 1rem;
    color: #000000;
    text-align: center;
    line-height: 1.2;
    width:90%;
    margin:0 auto;
    direction: rtl;
  }
  
  /* Max width 350px */
  @media (max-width: 350px) {
    .whyContainer {
      padding: 15px;
      width:280px
    }
    .title {
      font-size: 1.2rem;
    }
    .description {
      font-size: 0.9rem;
    }
  }
  
  /* 350px - 450px */
  @media (min-width: 350px) and (max-width: 450px) {
    .whyContainer {
      padding: 18px;
      width:300px
    }
    .title {
      font-size: 1.3rem;
    }
    .description {
      font-size: 0.95rem;
    }
  }
  
  /* 450px - 550px */
  @media (min-width: 450px) and (max-width: 550px) {
    .title {
      font-size: 1.4rem;
    }
  }
  
  /* 550px - 650px */
  @media (min-width: 550px) and (max-width: 650px) {
    .title {
      font-size: 1.5rem;
    }
  }
  
  /* 650px - 750px */
  @media (min-width: 650px) and (max-width: 750px) {
    .title {
      font-size: 1.4rem;
    }
  }
  
  /* 750px - 850px */
  @media (min-width: 750px) and (max-width: 850px) {
    .title {
      font-size: 1.5rem;
    }
  }
  