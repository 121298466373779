/* Base styles */
.content {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 2rem;
  border-radius: 6rem 4rem 6rem 4rem;
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px rgba(255, 255, 255, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.18);
  width: 300px;
  margin: 20px auto;
  position: relative;
  overflow: hidden;
}

.content::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 100%;
  height: 200%;
  background: radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%);
  transform: rotate(30deg);
}

.number {
  position: absolute;
  top: 0.5rem;
  right: 2rem;
  font-family: "Assistant";
  font-size: 1.5rem;

  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.8);
}

.title {
  font-family: 'Assistant', sans-serif;
  font-size: 1.2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 0.5rem;
  color: white;
}

.description {
  font-family: 'AssistantR', sans-serif;
  font-size: 1rem;
  color: white;
}

/* Responsive styles */
@media (max-width: 350px) {
  .content {
    width: 80%;
    padding: 1.5rem;
    border-radius: 3rem 2rem 3rem 2rem;
  }
  .number { font-size: 1.2rem; right: 1.5rem; }
  .title { font-size: 1.1rem; }
  .description { font-size: 1rem; }
}

@media (min-width: 350px) and (max-width: 450px) {
  .content {
    width: 290px;
    padding: 1.5rem;
    border-radius: 4rem 3rem 4rem 3rem;
  }
  .number { font-size: 1.3rem; }
  .title { font-size: 1.1rem; }
  .description { font-size: 0.95rem; }
}

