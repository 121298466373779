.container {
  display: flex;
  align-items: center;
  margin: 2% auto;
  color: black;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  direction: rtl;
  width: 320px;
  background: linear-gradient(145deg, #ffffff, #fff0f3);
  box-shadow: 
      10px 10px 20px rgba(255, 182, 193, 0.3),
      -10px -10px 20px rgba(255, 255, 255, 0.5),
      inset 5px 5px 10px rgba(255, 228, 225, 0.4),
      inset -5px -5px 10px rgba(255, 182, 193, 0.4);
  transition: all 0.3s ease;
}

.container:hover {
  transform: translateY(-3px);
  box-shadow: 
      15px 15px 30px rgba(255, 182, 193, 0.4),
      -15px -15px 30px rgba(255, 255, 255, 0.6),
      inset 8px 8px 16px rgba(255, 228, 225, 0.5),
      inset -8px -8px 16px rgba(255, 182, 193, 0.5);
}

.text {
  font-family: 'AssistantR', sans-serif;
  font-size: 1.15rem;
  direction: rtl;
  margin-right: 10px;
  width: 80%;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
}

.icon {
  width: 20%;
  filter: drop-shadow(2px 2px 3px rgba(255, 182, 193, 0.5));
}

@media (max-width: 350px) {
  .container {
      width: 270px;
      padding: 0.4rem 0.8rem;
      box-shadow: 
          5px 5px 10px rgba(255, 182, 193, 0.3),
          -5px -5px 10px rgba(255, 255, 255, 0.5),
          inset 3px 3px 6px rgba(255, 228, 225, 0.4),
          inset -3px -3px 6px rgba(255, 182, 193, 0.4);
  }

  .container:hover {
      box-shadow: 
          8px 8px 16px rgba(255, 182, 193, 0.4),
          -8px -8px 16px rgba(255, 255, 255, 0.6),
          inset 5px 5px 10px rgba(255, 228, 225, 0.5),
          inset -5px -5px 10px rgba(255, 182, 193, 0.5);
  }

  .text {
      width: 100%;
      font-size: 1rem;
      margin-bottom: 0.5rem;
  }

  .icon {
      width: 30%;
      text-align: center;
  }
}