.background {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  margin: 2% auto;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  direction: rtl;
  margin: 10px auto;
}

.background::before {
  content: '';
  border-radius: 200px 200px 0 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../images/מאי\ רקע.jpg');
  background-size: cover;
  background-position: center 20%;
  filter: blur(2px);
  z-index: -1;
}

.title, .description, .background > * {
  position: relative;
  z-index: 1;
  opacity: 1;
}

.title {
  font-family: 'Assistant';
  font-size: 2.5rem;
  direction: rtl;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 2% auto;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 1rem;
  border-radius: 1rem;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: rgba(255, 255, 255, 0.9);
}

.description {
  font-family: 'Assistant';
  font-size: 2rem;
  direction: rtl;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 2% auto;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 1rem;
  border-radius: 1rem;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: rgba(255, 255, 255, 0.9);
}

.bold {
  font-family: 'Assistant';
  font-size: 1.5rem;
  direction: rtl;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 2% auto;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 1rem;
  border-radius: 1rem;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: rgb(255, 255, 255);
}

.explain {
  font-family: 'AssistantR';
  font-size: 1.5rem;
  direction: rtl;
  text-align: center;
  color: #333;
  margin: 2% auto;
}

.little {
  font-family: 'AssistantR';
  font-size: 1.2rem;
  direction: rtl;
  text-align: center;
  color: #444;
  font-style: italic;
  margin: 1% auto;
}

/* Base styles remain unchanged */

/* Responsive styles */
@media (max-width: 350px) {
  .background::before {
    content: '';
    border-radius: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../images/מאי\ רקע.jpg');
    background-size: cover;
    background-position: center 20%;
    filter: blur(2px);
    z-index: -1;
  }
  .title { 
    font-size: 1.2rem; 
    padding: 0.5rem; 
    max-width: 80%;
  }
  .description { 
    font-size: 1rem; 
    padding: 0.5rem; 
    max-width: 90%;
  }
  .bold { 
    font-size: 0.9rem; 
    padding: 0.5rem; 
    max-width: 90%;
  }
  .row { flex-direction: column; }
  .explain { font-size: 1.2rem; }
  .little { font-size: 1rem; }
}

@media (min-width: 350px) and (max-width: 450px) {
  .background::before { 
    border-radius: 0;
    filter: blur(2px);
  }
  .title { 
    font-size: 1.2rem; 
    padding: 0.5rem; 
    max-width: 90%;
  }
  .description { 
    font-size: 1rem; 
    padding: 0.5rem; 
    max-width: 90%;
  }
  .bold { 
    font-size: 0.9rem; 
    padding: 0.5rem; 
    max-width: 90%;
  }
  .row { flex-direction: column; }
  .explain { font-size: 1.2rem; }
  .little { font-size: 1rem; }
}

@media (min-width: 450px) and (max-width: 550px) {
  .background::before { 
    border-radius: 0;
    filter: blur(2px);
  }
  .title { 
    font-size: 1.5rem; 
    padding: 0.5rem; 
    max-width: 85%;
  }
  .description { 
    font-size: 1.25rem; 
    padding: 0.5rem; 
    max-width: 90%;
  }
  .bold { 
    font-size: 1.1rem; 
    padding: 0.5rem; 
    max-width: 90%;
  }
  .explain { font-size: 1.3rem; }
  .little { font-size: 1.1rem; }
}

@media (min-width: 550px) and (max-width: 650px) {
  .background::before { 
    border-radius: 0;
    filter: blur(2px);
  }
  .title { 
    font-size: 1.8rem; 
    padding: 0.5rem; 
    max-width: 90%;
  }
  .description { 
    font-size: 1.4rem; 
    padding: 0.5rem; 
    max-width: 90%;
  }
  .bold { 
    font-size: 1.2rem; 
    padding: 0.5rem; 
    max-width: 90%;
  }
  .explain { font-size: 1.4rem; }
  .little { font-size: 1.2rem; }
}

@media (min-width: 650px) and (max-width: 750px) {
  .background::before { 
    border-radius: 0;
    filter: blur(2px);
  }
  .title { 
    font-size: 1.8rem; 
    padding: 0.5rem; 
    max-width: 80%;
  }
  .description { 
    font-size: 1.4rem; 
    padding: 0.5rem; 
    max-width: 90%;
  }
  .bold { 
    font-size: 1.2rem; 
    padding: 0.5rem; 
    max-width: 90%;
  }
  .explain { font-size: 1.4rem; }
  .little { font-size: 1.2rem; }
}

@media (min-width: 750px) and (max-width: 850px) {
  .background::before { 
    border-radius: 0;
    filter: blur(2px);
  }
  .title { 
    font-size:2rem; 
    padding: 0.5rem; 
    max-width: 80%;
  }
  .description { 
    font-size: 1.5rem; 
    padding: 0.5rem; 
    max-width: 90%;
  }
  .bold { 
    font-size: 1.25rem; 
    padding: 0.5rem; 
    max-width: 90%;
  }
  .explain { font-size: 1.5rem; }
  .little { font-size: 1.3rem; }
}

@media (min-width: 850px) and (max-width: 950px) {
  .background::before { 
    border-radius: 0;
    filter: blur(2px);
  }
  .title { 
    font-size: 2rem; 
    padding: 0.5rem; 
    max-width: 80%;
  }
  .description { 
    font-size: 1.5rem; 
    padding: 0.5rem; 
    max-width: 90%;
  }
  .bold { 
    font-size: 1.4rem; 
    padding: 0.5rem; 
    max-width: 90%;
  }
  .explain { font-size: 1.5rem; }
  .little { font-size: 1.3rem; }
}

@media (min-width: 950px) and (max-width: 1050px) {
  .background::before { 
    border-radius: 0;
    filter: blur(2px);
  }
  .title { 
    font-size: 2.2rem; 
    padding: 0.5rem; 
    max-width: 80%;
  }
  .description { 
    font-size: 1.5rem; 
    padding: 0.5rem; 
    max-width: 90%;
  }
  .bold { 
    font-size: 1.4rem; 
    padding: 0.5rem; 
    max-width: 90%;
  }
  .explain { font-size: 1.5rem; }
  .little { font-size: 1.3rem; }
}