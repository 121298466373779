.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 3rem;
  font-family: "Assistant", sans-serif;
  text-align: center;
  margin: 1% auto;
  text-shadow: 2px 4px 4px rgba(183, 110, 121, 0.3);
  direction: rtl;
  color: #333;
}

.form {
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 20px;
  direction: rtl;
  border-radius: 15px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.input {
  padding: 12px;
  margin: 10px 0;
  border: none;
  direction: rtl;
  border-radius: 8px;
  background: linear-gradient(135deg, rgba(255, 215, 200, 0.7), rgba(255, 192, 180, 0.7));
  font-size: 1.1rem;
  color: #333;
  font-family: "AssistantR", sans-serif;
  outline: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(183, 110, 121, 0.2),
              inset 0 0 10px rgba(255, 255, 255, 0.5);
}

.input::placeholder {
  opacity: 0.7;
  color: #282828;
}

.input:focus {
  background: linear-gradient(135deg, rgba(255, 192, 180, 0.8), rgba(255, 160, 150, 0.8));
  box-shadow: 0 6px 8px rgba(183, 110, 121, 0.3),
              inset 0 0 15px rgba(255, 255, 255, 0.7);
}

.button {
  padding: 12px;
  margin: 20px 0 0;
  border: none;
  border-radius: 8px;
  background: linear-gradient(135deg, rgba(255, 180, 160, 0.9), rgba(255, 150, 130, 0.9));
  font-size: 1.1rem;
  color: #fff;
  font-family: "Assistant", sans-serif;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(183, 110, 121, 0.3),
              inset 0 0 10px rgba(255, 255, 255, 0.5);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.button:hover {
  transform: translateY(-2px);
  background: linear-gradient(135deg, rgba(255, 160, 140, 1), rgba(255, 130, 110, 1));
  box-shadow: 0 6px 12px rgba(183, 110, 121, 0.4),
              inset 0 0 15px rgba(255, 255, 255, 0.7);
}

@media only screen and (max-width: 350px) {
  .title {
    font-size: 2rem;
  }
  
  .form {
    width: 100%;
  }
}

@media only screen and (min-width: 350px) and (max-width: 450px) {
  .title {
    font-size: 2rem;
    width: 90%;
  }
  
  .form {
    width: 300px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 550px) {
  .title {
    font-size: 2.2rem;
    width: 90%;
  }
}

@media only screen and (min-width: 550px) and (max-width: 750px) {
  .title {
    font-size: 2.2rem;
    width: 70%;
  }
}

@media only screen and (min-width: 750px) and (max-width: 950px) {
  .title {
    font-size: 2.5rem;
    width: 90%;
  }
}