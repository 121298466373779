/* Base styles */
.title {
  font-family: "Sevillana", cursive;
  font-size: 5rem;
  margin-bottom: 1rem;
  direction: rtl;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
}

.explain {
  font-family: "AssistantR";
  font-size: 1.5rem;
  margin: 2% auto;
  text-align: center;
  direction: rtl;
}

.bold {
  font-family: "Assistant";
  font-size: 2.5rem;
  margin: 2% auto;
  text-align: center;
  direction: rtl;
}

.boldR {
  font-family: "Assistant";
  font-size: 1.5rem;
  margin: 1% auto;
  color: #B76E79;
  text-decoration: underline;
  text-align: center;
  direction: rtl;
}

/* Responsive styles */
@media (max-width: 350px) {
  .title { font-size: 2.5rem; }
  .explain { font-size: 1rem; }
  .bold { font-size: 1.5rem; }
  .boldR { font-size: 1rem; }
}

@media (min-width: 350px) and (max-width: 450px) {
  .title { font-size: 3rem; }
  .explain { font-size: 1.1rem; }
  .bold { font-size: 1.7rem; }
  .boldR { font-size: 1.1rem; }
}

@media (min-width: 450px) and (max-width: 550px) {
  .title { font-size: 3.5rem; }
  .explain { font-size: 1.2rem; }
  .bold { font-size: 1.9rem; }
  .boldR { font-size: 1.2rem; }
}

@media (min-width: 550px) and (max-width: 650px) {
  .title { font-size: 4rem; }
  .explain { font-size: 1.3rem; }
  .bold { font-size: 2.1rem; }
  .boldR { font-size: 1.3rem; }
}

@media (min-width: 650px) and (max-width: 750px) {
  .title { font-size: 4.3rem; }
  .explain { font-size: 1.4rem; }
  .bold { font-size: 2.3rem; }
  .boldR { font-size: 1.4rem; }
}

@media (min-width: 750px) and (max-width: 850px) {
  .title { font-size: 4.5rem; }
  .explain { font-size: 1.45rem; }
  .bold { font-size: 2.4rem; }
  .boldR { font-size: 1.45rem; }
}

@media (min-width: 850px) and (max-width: 950px) {
  .title { font-size: 4.7rem; }
  .explain { font-size: 1.47rem; }
  .bold { font-size: 2.45rem; }
  .boldR { font-size: 1.47rem; }
}

@media (min-width: 950px) and (max-width: 1050px) {
  .title { font-size: 4.8rem; }
  .explain { font-size: 1.48rem; }
  .bold { font-size: 2.47rem; }
  .boldR { font-size: 1.48rem; }
}

@media (min-width: 1050px) and (max-width: 1150px) {
  .title { font-size: 4.9rem; }
  .explain { font-size: 1.49rem; }
  .bold { font-size: 2.48rem; }
  .boldR { font-size: 1.49rem; }
}

@media (min-width: 1150px) and (max-width: 1250px) {
  .title { font-size: 5rem; }
  .explain { font-size: 1.5rem; }
  .bold { font-size: 2.5rem; }
  .boldR { font-size: 1.5rem;}
}